/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
    
}

.accordion__button:hover {
    background-color: #ddd;
    
}

.accordion__button:after {
    display: inline-block;
    background-image: url("https://img.icons8.com/ios/512/plus.png");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 10px;
    top: 20px;
    content: "";
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
    margin-left: 100px;
    transition:transform 0.3s ease-in-out;
   
    /* border-bottom: 2px solid currentColor;
    border-left: 2px solid currentColor;
    transform: rotate(-135deg); */
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
 
    transform: rotate(-45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
