div.knifeHitName > h3 {
  color: #febb2f;
  font-size: 70px;
  font-weight: bold;
  text-shadow: -1px 2px 0 #cf202c, 1px 1px 10px #cf202c, 1px -1px 0 #cf202c,
    -1px -1px 0 #cf202c;
}

div.knifeHitName > p {
  color: #febb2f;
  font-size: 20px;
  font-weight: bold;
  text-shadow: -1px 2px 0 #cf202c, 1px 1px 0 #cf202c, 1px -1px 0 #cf202c,
    -1px -1px 0 #cf202c;
}

button.knifeHitPlayNow {
  background-color: #3a0e41;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px;
  color: white;
  width: 176px;
  font-weight: bold;
  font-size: 20px;
}

div.bg-knife-hit-banner {
  background-position: 10% 10%;
}

@media (max-width: 768px) {
  div.knifeHitName {
    width: 100%;
    margin-top: 30%;
  }
  div.knifeHitName > h3 {
    color: #febb2f;
    font-size: 45px;
    text-align: center;
  }

  div.knifeHitName > p {
    text-align: center;
    font-size: 17px;
    font-weight: bold;
  }

  button.knifeHitPlayNow {
    padding: 4px;
    color: white;
    width: 150px;
    font-weight: bold;
  }
}
