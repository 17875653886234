@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

$base-color: #170e1d;
$font-color: white;
$font-color2: #c2c2c2;
$button-color-2: #0ca218;
$button-color-1: #87de10;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body::-webkit-scrollbar {
  display: none;
}
.container {
  height: 90vmin;
}
.header {
  display: flex;
  justify-content: space-around;
  margin: auto;
  align-items: start;
  overflow: hidden;
}

.bg {
  background-image: url(../../images/calmverse/images/background.png);
  background-size: cover;
  background-position: center;
  min-width: 100vw;
  min-height: 110vmin;
  position: relative;
  top: -18vmin;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  .animation {
    position: absolute;
    width: 100vw;

    .left-animation {
      width: 20vw;
      height: 20vh;
      position: absolute;
      top: 70vh;
      left: 0;
    }
    .right-animation {
      width: 20vw;
      height: 20vh;
      position: absolute;
      top: 70vh;
      right: 0;
    }
  }
}
.bg::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vh; /* Adjust the height of the shadow as needed */
  background: linear-gradient(to top, #170e1d 0%, transparent 100%);
  pointer-events: none;
}

.heading {
  top: 27vmax;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  width: 65%;
  color: white;
  font-size: 2.7vmax;
  font-weight: 900;
  font-family: "Poppins", sans-serif;
  p {
    font-size: 1.3vmax;
    font-weight: 300;
    color: $font-color2;
    text-align: center;
    margin-top: 1.5vh;
  }
}
.download-now {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 43vmax;
  position: absolute;
  left: 30vw;
  right: 30vw;
  text-align: center;
  z-index: 1;
  width: 40vw;
  gap: 3vw;

  img {
    border-radius: 0.4vw;
    height: 8vmin;
    width: 12vmax;
    cursor: pointer;
  }
  img:hover {
    transform: scale(1.1);
    transition: transform 0.4s;
  }
}

.blur-effect::before {
  content: "";

  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px; /* Adjust the height of the shadow as needed */
  background: linear-gradient(to bottom, #170e1d 0%, transparent 100%);
  pointer-events: none;
}

.main-content {
  background-color: $base-color;
  z-index: -1;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 4vmin;
  color: $font-color;
  .animation {
    position: absolute;
    width: 100vw;

    .left-animation {
      width: 20vw;
      height: 20vh;
      position: absolute;
      top: 0;
      left: 0;
    }
    .right-animation {
      width: 20vw;
      height: 20vh;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .sub-heading {
    min-width: 100vw;
    max-height: 25vmin;
    background-color: $base-color;
    margin-top: 20vh;

    h2 {
      font-weight: 600;
      font-size: 3vmax;
    }
  }
  .sub-heading-text {
    color: $font-color2;
    font-size: 1.3vmax;
    margin-top: 5vmin;
    font-weight: 300;
  }
  button {
    margin-top: 11vmin !important;
    background: $button-color-1;
    background: linear-gradient(
      90deg,
      $button-color-1 2%,
      $button-color-2 100%
    );
    height: 10vh;
    width: 24vw;
    font-size: 2vmax;
    border-radius: 5vmin;
    color: white;
    font-weight: 900;
  }
  button:hover {
    opacity: 0.7;
  }
  .services {
    margin-top: 20vmin;
    min-width: 80vw;
    h2 {
      font-size: 3vmax;
      font-weight: 800;
    }
    .icons {
      margin-top: 12vmin;
      display: flex;
      justify-content: space-between;

      .icon {
        display: flex;
        flex-direction: column;

        align-items: center;
        width: 20vmax;
        h2 {
          margin-top: 8vmin;
          font-size: 1.7vmax;
          font-weight: 800;
        }
        p {
          margin-top: 2vmin;
          text-align: center;
          font-size: 1.3vmax;
          color: $font-color2;
          font-weight: 300;
        }
      }
    }
  }
}
.middle-banner {
  background-image: url(../../images/calmverse/images/middleBanner.png);
  background-size: cover;
  background-position: center;
  min-width: 100vw;
  min-height: 80vh;
  position: relative;
  top: 0;
  left: 0;
  z-index: -0.5;
  background-repeat: no-repeat;
  margin-top: 20vh;
  .middle-banner-content {
    position: absolute;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 20vmin;
    right: 10vmax;
    h1 {
      font-size: 1.8vmax;
      font-weight: 700;
    }
  }
}

.faq {
  margin-top: 20vh;
  h1 {
    font-size: 3vmax;
    font-weight: 600;
  }
}
.faq-accordion {
  width: 60vmax;
  .accordion-item {
    border: 0.5px solid #1a6720;
    text-align: left;
    padding: 1vmax;
  }

  .accordion-question {
    color: $font-color;
    position: relative;
    cursor: pointer;
    width: 50vmax;
  }
  .accordion-question:after {
    display: inline-block;
    background-image: url(../../images/calmverse/images/downArrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 1vmax;

    content: "";
    background-size: 30px 30px;
    height: 32px;
    width: 32px;
    margin-left: 100px;
    transition: transform 0.2s ease-in-out;
  }

  .accordion-question[aria-expanded="true"]:after {
    transform: rotate(180deg);
  }

  .accordion-answer {
    padding-top: 1vmin;
    width: 40vmax;
    font-size: 1.3vmax;
    font-weight: 300;
    color: $font-color2;
  }
  .accordion__panel {
    padding: 0;
  }
}

@media (max-width: 1250px) {
  .middle-banner {
    background-image: url(../../images/calmverse/images/middleBanner.png);
    background-size: cover;
    background-position: center;
    min-width: 100vw;
    min-height: 80vh;
    position: relative;
    top: 0;
    left: 0;
    z-index: -0.5;
    background-repeat: no-repeat;
    margin-top: 20vh;
    .middle-banner-content {
      position: absolute;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 12vmin;
      right: 10vmax;
      h1 {
        font-size: 1.7vmax;
        font-weight: 700;
      }
      p {
        font-size: 1.3vmax;
      }
    }
  }
}
@media screen and (max-width: 950px) and (min-height: 720px) {
  /* Your CSS rules for screens with a maximum width of 768px and a maximum height of 1024px go here */
  .download-now {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    right: 10vw;
    left: 10vw;
    top: 80vw;
    width: 80vw;
    z-index: 1;

    img {
      border-radius: 0.4vw;
      height: 3.6vh;
      width: 25vw;
    }
  }
}

@media (max-width: 913px) {
  .bg {
    background-image: url(../../images/calmverse/images/background.png);
    background-size: cover;
    background-position: 25% 25%;
    min-width: 100vw;
    min-height: 142vmin;
    position: relative;
    top: -18vmin;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    .animation {
      position: absolute;
      width: 100vw;

      .left-animation {
        width: 20vw;
        height: 20vh;
        position: absolute;
        top: 40vh;
        left: 0;
      }
      .right-animation {
        width: 20vw;
        height: 20vh;
        position: absolute;
        top: 40vh;
        right: 0;
      }
    }
  }
  .bg::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60vh; /* Adjust the height of the shadow as needed */
    background: linear-gradient(to top, #170e1d 0%, transparent 100%);
    pointer-events: none;
  }
  .blur-effect::before {
    content: "";

    bottom: 0;
    left: 0;
    width: 100%;
    height: 5vh; /* Adjust the height of the shadow as needed */
    background: linear-gradient(to bottom, #170e1d 0%, transparent 100%);
    pointer-events: none;
  }

  .faq {
    justify-content: center;
    align-items: center;
    margin-top: 15vh;
    h1 {
      margin-top: 8vh;
      font-weight: 600;
      font-size: 2.3vmax;
    }
  }
  .faq-accordion {
    margin-left: auto;
    margin-right: auto;
  }
  .faq-accordion-container {
    max-width: 500px;
  }
  .faq-accordion {
    width: 500px;
    .accordion-item {
      border: 2px solid #1a6720;
      text-align: left;
      padding: 1.2vmax;
    }

    .accordion-question {
      color: $font-color;
      position: relative;
      cursor: pointer;
      max-width: 470px;
      font-size: 1.5vmax;
    }
    .accordion-question:after {
      display: inline-block;
      background-image: url(../../images/calmverse/images/downArrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 1vmax;

      content: "";
      background-size: 24px 24px;
      height: 24px;
      width: 24px;
      margin-left: 0px;
      transition: transform 0.2s ease-in-out;
    }
    .accordion-question[aria-expanded="true"]:after {
      transform: rotate(180deg);
    }

    .accordion-answer {
      padding-top: 1vmin;
      max-width: 470px;
      font-size: 1.5vmax;
    }
    .accordion__panel {
      padding: 0;
    }
  }
  .main-content {
    background-color: $base-color;
    z-index: -1;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 4vmin;
    color: $font-color;
    margin-top: 20vh;

    .sub-heading {
      min-width: 80vw;
      max-height: 25vmin;
      background-color: $base-color;
      margin-top: 37vw;

      h2 {
        font-weight: 600;
        font-size: 2.3vmax;
      }
    }
    .sub-heading-text {
      margin-top: 3vh;
      p {
        color: $font-color2;
        font-size: 1.7vmax !important;

        width: 85% !important;

        margin: auto;
        br {
          display: none;
        }
      }
    }
    button {
      margin-top: 8vmin;
      background: $button-color-1;
      background: linear-gradient(
        90deg,
        $button-color-1 2%,
        $button-color-2 100%
      );
      height: 6vh;
      width: 20vh;
      font-size: 2vmax;
      border-radius: 5vmin;
      color: white;
      border-radius: 4vh;
    }

    .services {
      margin-top: 20vmin;

      h2 {
        font-weight: 600;
        font-size: 2vmax;
      }
      .icons {
        margin-top: 8vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 75vw;
          margin-bottom: 8vh;

          h2 {
            margin-top: 8vmin;
            font-size: 1.7vmax;
            font-weight: 800;
          }
          p {
            margin-top: 2vmin;
            text-align: center;
            font-size: 1.3vmax;
          }
        }
      }
    }
  }
  .middle-banner {
    background-image: url(../../images/calmverse/images/middleBanner.png);
    background-size: cover;
    background-position: center;
    min-width: 100vw;
    min-height: 70vh;
    position: relative;
    top: 0;
    left: 0;
    z-index: -0.5;
    background-repeat: no-repeat;
    margin-top: 4vh;
    .middle-banner-content {
      position: absolute;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 15vh;
      right: 10vmax;
      h1 {
        font-size: 2vmax;
        font-weight: 700;
      }
      p {
        font-size: 1.5vmax;
      }
    }
  }
  .heading {
    top: 70vw;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    width: 75%;
    color: white;
    font-weight: 600;
    font-size: 2.3vmax;
    font-family: "Poppins", sans-serif;
    p {
      color: $font-color2;
      font-size: 1.7vmax;
      br {
        display: none;
      }
    }
  }
  .download-now {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    right: 10vw;
    left: 10vw;
    top: 110vw;
    width: 80vw;
    z-index: 1;

    img {
      border-radius: 0.4vw;
      height: 5.6vh;
      width: 25vw;
    }
  }
  .side-leaf-top {
    margin-top: 8vw !important;
    width: 20vw !important;
    height: 15vh !important;
  }
  .side-leaf-bottom {
    margin-top: 8vw !important;
    width: 20vw !important;
    height: 15vh !important;
  }
}
@media (max-width: 530px) {
  .bg {
    background-image: url(../../images/calmverse/images/background.png);
    background-size: cover;
    background-position: 25% 25%;
    min-width: 100vw;
    min-height: 142vmin;
    position: relative;
    top: -18vmin;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    .animation {
      position: absolute;
      width: 100vw;

      .left-animation {
        width: 20vw;
        height: 20vh;
        position: absolute;
        top: 40vh;
        left: 0;
      }
      .right-animation {
        width: 20vw;
        height: 20vh;
        position: absolute;
        top: 40vh;
        right: 0;
      }
    }
  }
  .bg::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60vh; /* Adjust the height of the shadow as needed */
    background: linear-gradient(to top, #170e1d 0%, transparent 100%);
    pointer-events: none;
  }
  .blur-effect::before {
    content: "";

    bottom: 0;
    left: 0;
    width: 100%;
    height: 5vh; /* Adjust the height of the shadow as needed */
    background: linear-gradient(to bottom, #170e1d 0%, transparent 100%);
    pointer-events: none;
  }

  .faq {
    justify-content: center;
    align-items: center;
    margin-top: 15vh;
    h1 {
      margin-top: 8vh;
      font-weight: 600;
      font-size: 2vmax;
    }
  }
  .faq-accordion {
    margin-left: auto;
    margin-right: auto;
  }
  .faq-accordion-container {
    max-width: 350px;
  }
  .faq-accordion {
    width: 350px;
    .accordion-item {
      border: 2px solid #1a6720;
      text-align: left;
      padding: 1vmax;
    }

    .accordion-question {
      color: $font-color;
      position: relative;
      cursor: pointer;
      max-width: 315px;
    }
    .accordion-question:after {
      display: inline-block;
      background-image: url(../../images/calmverse/images/downArrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 1vmax;

      content: "";
      background-size: 20px 20px;
      height: 20px;
      width: 20px;
      margin-left: 0px;
      transition: transform 0.2s ease-in-out;
    }
    .accordion-question[aria-expanded="true"]:after {
      transform: rotate(180deg);
    }

    .accordion-answer {
      padding-top: 1vmin;
      max-width: 250px;
    }
    .accordion__panel {
      padding: 0;
    }
  }
  .main-content {
    background-color: $base-color;
    z-index: -1;
    min-height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 4vmin;
    color: $font-color;
    margin-top: 15vh;

    .sub-heading {
      min-width: 80vw;
      max-height: 25vmin;
      background-color: $base-color;
      margin-top: 37vw;

      h2 {
        font-weight: 600;
        font-size: 2vmax;
      }
    }
    .sub-heading-text {
      margin-top: 3vh;
      p {
        color: $font-color2;
        font-size: 1.3vmax !important;

        width: 85% !important;

        margin: auto;
        br {
          display: none;
        }
      }
    }
    button {
      margin-top: 8vmin;
      background: $button-color-1;
      background: linear-gradient(
        90deg,
        $button-color-1 2%,
        $button-color-2 100%
      );
      height: 6vh;
      width: 20vh;
      font-size: 2vmax;
      border-radius: 5vmin;
      color: white;
      border-radius: 4vh;
    }

    .services {
      margin-top: 20vmin;

      h2 {
        font-weight: 600;
        font-size: 2vmax;
      }
      .icons {
        margin-top: 8vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 75vw;
          margin-bottom: 8vh;

          h2 {
            margin-top: 8vmin;
            font-size: 1.7vmax;
            font-weight: 800;
          }
          p {
            margin-top: 2vmin;
            text-align: center;
            font-size: 1.3vmax;
          }
        }
      }
    }
  }
  .middle-banner {
    background-image: url(../../images/calmverse/images/middleBanner.png);
    background-size: cover;
    background-position: center;
    min-width: 100vw;
    min-height: 70vh;
    position: relative;
    top: 0;
    left: 0;
    z-index: -0.5;
    background-repeat: no-repeat;
    margin-top: 4vh;
    .middle-banner-content {
      position: absolute;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 15vh;
      right: 10vmax;
      h1 {
        font-size: 2vmax;
        font-weight: 700;
      }
      p {
        font-size: 1.5vmax;
      }
    }
  }
  .heading {
    top: 80vw;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    width: 75%;
    color: white;
    font-weight: 600;
    font-size: 2vmax;
    font-family: "Poppins", sans-serif;
    p {
      color: $font-color2;
      font-size: 1.3vmax;
      br {
        display: none;
      }
    }
  }
  .download-now {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    right: 10vw;
    left: 10vw;
    top: 122vw;
    width: 80vw;
    z-index: 1;

    img {
      border-radius: 0.4vw;
      height: 8vw;
      width: 25vw;
    }
  }
  .side-leaf-top {
    margin-top: 8vw !important;
    width: 20vw !important;
    height: 15vh !important;
  }
  .side-leaf-bottom {
    margin-top: 8vw !important;
    width: 20vw !important;
    height: 15vh !important;
  }
}
