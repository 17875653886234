@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Bai Jamjuree", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 500px) {
  img#stackball {
    display: none !important;
  }
}

a:link {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

a:active {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

#reviewDiv .slick-track {
  margin: 20px;
}

#faqPageCss {
  background-image: linear-gradient(
    180deg,
    hsl(0deg 0% 100%) 0%,
    hsl(259deg 81% 97%) 7%,
    hsl(259deg 81% 93%) 11%,
    hsl(258deg 80% 90%) 15%,
    hsl(257deg 79% 86%) 18%,
    hsl(257deg 78% 83%) 21%,
    hsl(256deg 78% 79%) 25%,
    hsl(255deg 77% 76%) 31%,
    hsl(254deg 76% 72%) 40%,
    hsl(252deg 76% 69%) 56%,
    hsl(250deg 75% 65%) 100%
  );
}

img {
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari, Chrome, Opera, Samsung */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Firefox */
  -ms-user-select: none !important; /* Edge, IE */
  user-select: none !important; /* Modern browsers */
}

a {
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari, Chrome, Opera, Samsung */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Firefox */
  -ms-user-select: none !important; /* Edge, IE */
  user-select: none !important; /* Modern browsers */
}

h2.ajay {
  animation: color-change 1s infinite;
}

@keyframes color-change {
  0% {
    color: rgba(255, 255, 255, 0.9);
  }
  50% {
    color: rgba(0, 0, 255, 0.5);
  }
  100% {
    color: rgba(255, 255, 255, 0.9);
  }
}

#faqContainer {
  background-image: linear-gradient(
    39deg,
    hsl(220deg 88% 62%) 1%,
    hsl(222deg 88% 65%) 33%,
    hsl(223deg 88% 67%) 41%,
    hsl(224deg 88% 70%) 46%,
    hsl(226deg 88% 72%) 48%,
    hsl(227deg 88% 74%) 50%,
    hsl(227deg 88% 76%) 51%,
    hsl(228deg 88% 78%) 51%,
    hsl(229deg 88% 80%) 51%,
    hsl(230deg 88% 81%) 51%,
    hsl(230deg 88% 83%) 50%,
    hsl(231deg 89% 85%) 50%,
    hsl(231deg 89% 86%) 49%,
    hsl(232deg 89% 88%) 49%,
    hsl(232deg 89% 90%) 49%,
    hsl(233deg 90% 91%) 49%,
    hsl(233deg 90% 93%) 50%,
    hsl(234deg 90% 94%) 52%,
    hsl(234deg 91% 96%) 54%,
    hsl(235deg 91% 97%) 59%,
    hsl(235deg 91% 99%) 67%,
    hsl(0deg 0% 100%) 99%
  );
}

@media (max-width: 500px) {
  #faqContainer {
    background-image: linear-gradient(
      79deg,
      hsl(220deg 88% 62%) 1%,
      hsl(222deg 88% 65%) 33%,
      hsl(223deg 88% 67%) 41%,
      hsl(224deg 88% 70%) 46%,
      hsl(226deg 88% 72%) 48%,
      hsl(227deg 88% 74%) 50%,
      hsl(227deg 88% 76%) 51%,
      hsl(228deg 88% 78%) 51%,
      hsl(229deg 88% 80%) 51%,
      hsl(230deg 88% 81%) 51%,
      hsl(230deg 88% 83%) 50%,
      hsl(231deg 89% 85%) 50%,
      hsl(231deg 89% 86%) 49%,
      hsl(232deg 89% 88%) 49%,
      hsl(232deg 89% 90%) 49%,
      hsl(233deg 90% 91%) 49%,
      hsl(233deg 90% 93%) 50%,
      hsl(234deg 90% 94%) 52%,
      hsl(234deg 91% 96%) 54%,
      hsl(235deg 91% 97%) 59%,
      hsl(235deg 91% 99%) 67%,
      hsl(0deg 0% 100%) 99%
    );
  }
}

#blogContent a {
  color: blue;
}

#blogContent img {
  border-radius: 20px;
  height: 500px;
  margin: 20px auto;
}
@media (max-width: 500px) {
  #blogContent img {
    object-fit: center;
    height: 200px;
    object-position: left;
  }
}
