@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@400;700&display=swap");

$primary: white;
$secondary: #c3c3c3;

.slide1 {
  position: relative;
  font-family: "Poppins", sans-serif;

  .bg1 {
    background-image: url(../../images/games/images/Knife_hit_BG.png);
    background-size: cover;
    background-position: center;
    min-width: 100vw;
    min-height: 100vmin;

    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
  }
  .bg1::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25vh; /* Adjust the height of the shadow as needed */
    background: linear-gradient(to top, rgb(12, 12, 12) 0%, transparent 100%);
    pointer-events: none;
  }
}

.slide1 {
  // .mobile {
  //   top: 9vmax;

  //   left: 10vw;

  //   position: absolute;
  //   width: 30vmax;
  //   z-index: 1;
  //   img {
  //     height: 70vmin;
  //   }
  // }
  .mobile-video {
    top: 9.3vmax;

    left: 10.9vmax;
    border: 6px solid rgb(218, 216, 216);
    position: absolute;
    width: 16.6vmax;
    z-index: 2;
    object-fit: cover;
    height: 69.5vmin;
    border-radius: 5vmin;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
    video {
      border: 1vmin solid black;
      height: 68vmin;
      border-radius: 4vmin;
      width: 16.6vmax;
      object-fit: cover;
    }
  }
  .slide1-heading {
    top: 12vmax;
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
    margin-right: auto;
    left: 25vw;
    right: 0;
    text-align: center;
    position: absolute;
    width: 35vw;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2.7vw;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    .title-img {
      width: 25vw;
      height: 18vh;
    }

    .slide1-text {
      margin-top: 5vh;
      font-size: 2.5vh;
    }
    .downloads {
      display: flex;
      justify-content: center;
      gap: 2vmin;
      margin-top: 10vh;
      flex-wrap: wrap;
      img {
        width: 15vw;
        height: 8vh;
      }
      img:hover {
        transform: scale(1.05);
        transition: transform 0.2s;
      }
    }
  }

  .juggler {
    top: 15vmax;

    right: 5%;

    position: absolute;
    width: 15%;
    img {
      height: 55vh;
    }
  }
}

.slide2 {
  position: relative;
  font-family: "Roboto", sans-serif;

  .bg2 {
    background-image: url(../../images/games/images/War_tank_BG.png);
    background-size: cover;
    background-position: center;
    min-width: 100vw;
    min-height: 100vmin;

    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
  }
}
.slide2 {
  .slide2-heading {
    top: 12vmax;

    margin-right: auto;
    left: 10%;
    right: 0;
    width: 30%;
    position: absolute;

    color: black;

    h1 {
      font-size: 8vmin;
      font-weight: 1000;
    }
    h2 {
      font-size: 2.5vmax;
      font-weight: 400;
      margin-top: -1vh;
    }
    p {
      margin-top: 3vh;
      font-size: 2.5vh;
    }
    .downloads {
      display: flex;

      gap: 2vmin;
      margin-top: 8vh;
      img {
        width: 15vw;
        height: 8vh;
      }
      img:hover {
        transform: scale(1.05);
        transition: transform 0.2s;
      }
    }
  }
}
.main-content-games {
  font-family: "Poppins", sans-serif;

  margin-top: -2vh;
  background: linear-gradient(180deg, #0f0b0c 15%, #0e2d5d 80%, #1a4581 100%);
  min-height: 100vh;
  padding-bottom: 10vh;
  .heading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .game-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 60%;
    text-align: center;
    h1 {
      margin-top: 20vh;
      font-size: 6vh;
      font-weight: 900;
    }
    p {
      color: $secondary;
      font-size: 2vmin;
    }
  }

  // .slick-prev,
  // .slick-next {
  //   display: none !important;
  // }

  // display: flex !important;

  // justify-content: space-evenly !important;
  // flex-wrap: wrap !important;
  // align-items: end !important;
  .games {
    font-family: "Poppins", sans-serif;

    margin: auto;
    margin-top: 12vh;

    width: 90vw;
  }
  .game {
    display: flex !important;
    flex-direction: column;
    color: white;
    text-align: start;
    align-items: start !important;
    font-family: "Poppins", sans-serif;

    h2 {
      margin-top: 2vmin;
      font-weight: 900;
      margin-left: 2vmin;
      font-size: 2.5vmin;
    }
    p {
      margin-top: 1vmin;
      font-weight: 400;
      margin-left: 2vmin;
      color: $secondary;
      font-size: 2vmin;
      width: 80%;
    }

    .playstore {
      height: 4vmin;
      width: 7vmax;
      margin-top: 3vmin;
      margin-left: 2vmin;
    }
    .playstore:hover {
      opacity: 0.7;
    }
  }

  .explore-button {
    font-family: "Poppins", sans-serif;

    display: flex;

    justify-content: center;
    margin-top: 15vh;

    button {
      color: white;
      background-color: #a01b25;
      font-size: 2.4vmin;
      font-weight: 900;
      width: 12vw;
      height: 7vh;
    }
    button:hover {
      opacity: 0.85;
    }
  }
}
.game-faq {
  font-family: "Poppins", sans-serif;

  margin-top: 25vmin;
  color: white;
  font-size: 6vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}
.faq-game-accordion {
  font-family: "Poppins", sans-serif;

  width: 70vmax;
  .accordion-game-item {
    text-align: left;
    padding: 1vmax;
  }

  .accordion-game-question {
    font-family: "Poppins", sans-serif;

    color: white;
    position: relative;
    cursor: pointer;
    width: 60vmax;
  }
  .accordion-game-question:after {
    display: inline-block;
    background-image: url(../../images/games/images/plus.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 1vmax;

    content: "";
    background-size: 30px 30px;
    height: 1.3vmax;
    width: 1.3vmax;
    margin-left: 8vw;
    transition: transform 0.2s ease-in-out;
  }
  .accordion-game-question[aria-expanded="true"]:after {
    transform: rotate(120deg) scale(4) rotate(105deg) scale(0.25);
    transition: transform 0.7s;
  }
  .accordion-game-question[aria-expanded="false"]:after {
    transition: transform 0.7s;
  }

  .accordion-game-answer {
    font-family: "Poppins", sans-serif;

    padding-top: 1vmin;
    width: 50vmax;
    color: $secondary;
  }
  .accordion__panel {
    padding: 0;
  }
}
.multiple-categories {
  font-family: "Poppins", sans-serif;

  h1 {
    margin-top: 25vmin;
    color: white;
    font-size: 6vmin;
    width: 100vw;
    font-weight: 900;
    text-align: center;
  }
}
.category-filter {
  font-family: "Poppins", sans-serif;

  margin-top: 10vmin !important;
  width: 75%;
  display: flex;
  justify-content: space-around;
  margin: auto;

  .category-box {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #204a89;
    width: 12vw;
    height: 7vh;
    text-align: center;
    border-radius: 1vh;
    cursor: pointer;
  }
  .category-box:hover {
    opacity: 0.8;
  }
  .active {
    color: white;
    background-color: #2e68ea;
    transform: scale(1.1);
    transition: transform 0.2s;
    font-weight: 900;
  }
  .active:hover {
    opacity: 1;
  }
}

.cards {
  font-family: "Poppins", sans-serif;
  width: 70vw;
  display: flex;
  justify-content: start;
  margin: auto;
  color: white;
  margin-top: 10vmin;
  flex-wrap: wrap;
  row-gap: 8vmin;
  column-gap: 5vmin;

  .card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: transparent;
    border: transparent;
    img {
      height: 30vmin;
      width: 30vmin;
      border-radius: 1vh;
    }
    h2 {
      text-align: center;
      margin-top: 2vmin;
      font-weight: 700;
      color: #c3c3c3;
      font-size: 2.5vmin;
    }
    p {
      margin-top: 1vmin;
      text-align: center;
      font-size: 2vmin;
      color: $secondary;
      width: 30vmin;
    }
    .download-game {
      height: 4vmin;
      width: 7vmax;

      margin: auto;
      margin-top: 3vmin;
    }
    .download-game:hover {
      opacity: 0.7;
    }
  }
}
@media (max-width: 1300px) {
  .slide1 {
    .mobile-video {
      top: 9.3vmax;

      left: 10.9vmax;
      border: 6px solid rgb(218, 216, 216);
      position: absolute;
      width: 16.6vmax;
      z-index: 2;
      object-fit: cover;
      height: 59.5vmin;
      border-radius: 5vmin;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
      video {
        border: 1vmin solid black;
        height: 58vmin;
        border-radius: 4vmin;
        width: 16.6vmax;
        object-fit: cover;
      }
    }
  }
  .main-content-games {
    font-family: "Poppins", sans-serif;

    margin-top: -2vh;
    background: linear-gradient(180deg, #0f0b0c 15%, #0e2d5d 80%, #1a4581 100%);
    min-height: 100vh;
    padding-bottom: 10vh;
    .heading-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .game-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 60%;
      text-align: center;
      h1 {
        margin-top: 20vh;
        font-size: 6vh;
        font-weight: 900;
      }
      p {
        color: $secondary;
        font-size: 2vmin;
      }
    }

    // .slick-prev,
    // .slick-next {
    //   display: none !important;
    // }

    // display: flex !important;

    // justify-content: space-evenly !important;
    // flex-wrap: wrap !important;
    // align-items: end !important;
    .games {
      font-family: "Poppins", sans-serif;

      margin: auto;
      margin-top: 12vh;

      width: 75vw;
    }
    .game {
      display: flex !important;
      flex-direction: column;
      color: white;
      text-align: start;
      align-items: start !important;
      font-family: "Poppins", sans-serif;

      h2 {
        margin-top: 2vmin;
        font-weight: 900;
        margin-left: 2vmin;
        font-size: 2.5vmin;
      }
      p {
        margin-top: 1vmin;
        font-weight: 400;
        margin-left: 2vmin;
        color: $secondary;
        font-size: 2vmin;
      }

      .playstore {
        height: 4vmin;
        width: 7vmax;
        margin-top: 3vmin;
        margin-left: 2vmin;
      }
      .playstore:hover {
        opacity: 0.7;
      }
    }

    .explore-button {
      font-family: "Poppins", sans-serif;

      display: flex;

      justify-content: center;
      margin-top: 15vh;

      button {
        color: white;
        background-color: #a01b25;
        font-size: 2.4vmin;
        font-weight: 900;
        width: 12vw;
        height: 7vh;
      }
      button:hover {
        opacity: 0.85;
      }
    }
  }
}
@media (max-width: 1024px) {
  .slide1 {
    .mobile-video {
      top: 9.3vmax;

      left: 10.9vmax;
      border: 6px solid rgb(218, 216, 216);
      position: absolute;
      width: 16.6vmax;
      z-index: 2;
      object-fit: cover;
      height: 59.5vmin;
      border-radius: 5vmin;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
      video {
        border: 1vmin solid black;
        height: 58vmin;
        border-radius: 4vmin;
        width: 16.6vmax;
        object-fit: cover;
      }
    }
  }
  .main-content-games {
    font-family: "Poppins", sans-serif;

    margin-top: -2vh;
    background: linear-gradient(180deg, #0f0b0c 15%, #0e2d5d 80%, #1a4581 100%);
    min-height: 100vh;
    padding-bottom: 10vh;
    .heading-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .game-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 60%;
      text-align: center;
      h1 {
        margin-top: 20vh;
        font-size: 6vh;
        font-weight: 900;
      }
      p {
        color: $secondary;
        font-size: 2vmin;
      }
    }

    // .slick-prev,
    // .slick-next {
    //   display: none !important;
    // }

    // display: flex !important;

    // justify-content: space-evenly !important;
    // flex-wrap: wrap !important;
    // align-items: end !important;
    .games {
      font-family: "Poppins", sans-serif;

      margin: auto;
      margin-top: 12vh;

      width: 60vw;
    }
    .game {
      display: flex !important;
      flex-direction: column;
      color: white;
      text-align: start;
      align-items: start !important;
      font-family: "Poppins", sans-serif;

      h2 {
        margin-top: 2vmin;
        font-weight: 900;
        margin-left: 2vmin;
        font-size: 2.5vmin;
      }
      p {
        margin-top: 1vmin;
        font-weight: 400;
        margin-left: 2vmin;
        color: $secondary;
        font-size: 2vmin;
      }

      .playstore {
        height: 4vmin;
        width: 7vmax;
        margin-top: 3vmin;
        margin-left: 2vmin;
      }
      .playstore:hover {
        opacity: 0.7;
      }
    }

    .explore-button {
      font-family: "Poppins", sans-serif;

      display: flex;

      justify-content: center;
      margin-top: 15vh;

      button {
        color: white;
        background-color: #a01b25;
        font-size: 2.4vmin;
        font-weight: 900;
        width: 12vw;
        height: 7vh;
      }
      button:hover {
        opacity: 0.85;
      }
    }
  }
}
@media (max-width: 1025px) and (min-height: 1300px) {
  .slide1 {
    // .mobile {
    //   top: 9vmax;

    //   left: 10vw;

    //   position: absolute;
    //   width: 30vmax;
    //   z-index: 1;
    //   img {
    //     height: 70vmin;
    //   }
    // }
    .mobile-video {
      top: 15vw;

      left: 6.9vw;
      border: 6px solid rgb(218, 216, 216);
      position: absolute;
      width: 22.6vw;
      z-index: 2;
      object-fit: cover;
      height: 45.5vw;
      border-radius: 5vmin;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
      video {
        border: 1vmin solid black;
        height: 44vw;
        border-radius: 4vmin;
        width: 22vw;
        object-fit: cover;
      }
    }
    .slide1-heading {
      top: 15vw;
      display: flex;
      flex-direction: column;
      margin-left: 10vw;
      margin-right: auto;
      left: 25vw;
      right: 0;
      text-align: center;
      position: absolute;
      width: 35vw;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 2.7vw;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      .title-img {
        width: 38vw;
        height: 19vw;
      }

      .slide1-text {
        margin-top: 3vw;
        font-size: 2vw;
      }
      .downloads {
        display: flex;
        justify-content: center;
        gap: 2vmin;
        margin-top: 10vw;
        flex-wrap: wrap;
        img {
          width: 12vw;
          height: 6vw;
        }
        img:hover {
          transform: scale(1.05);
          transition: transform 0.2s;
        }
      }
    }

    .juggler {
      top: 8%;

      right: 2%;

      position: absolute;
      width: 25%;
      img {
        height: 75vw;
      }
    }
  }
}
@media (max-width: 1023px) and (min-height: 595px) {
  .slide1 {
    // .mobile {
    //   top: 9vmax;

    //   left: 10vw;

    //   position: absolute;
    //   width: 30vmax;
    //   z-index: 1;
    //   img {
    //     height: 70vmin;
    //   }
    // }
    .mobile-video {
      top: 15vw;

      left: 6.9vw;
      border: 6px solid rgb(218, 216, 216);
      position: absolute;
      width: 22.6vw;
      z-index: 2;
      object-fit: cover;
      height: 45.5vw;
      border-radius: 5vmin;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
      video {
        border: 1vmin solid black;
        height: 44vw;
        border-radius: 4vmin;
        width: 22vw;
        object-fit: cover;
      }
    }
    .slide1-heading {
      top: 15vw;
      display: flex;
      flex-direction: column;
      margin-left: 10vw;
      margin-right: auto;
      left: 25vw;
      right: 0;
      text-align: center;
      position: absolute;
      width: 35vw;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 2.7vw;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      .title-img {
        width: 38vw;
        height: 19vw;
      }

      .slide1-text {
        margin-top: 3vw;
        font-size: 2vw;
      }
      .downloads {
        display: flex;
        justify-content: center;
        gap: 2vmin;
        margin-top: 10vw;
        flex-wrap: wrap;
        img {
          width: 12vw;
          height: 6vw;
        }
        img:hover {
          transform: scale(1.05);
          transition: transform 0.2s;
        }
      }
    }

    .juggler {
      top: 8%;

      right: 2%;

      position: absolute;
      width: 25%;
      img {
        height: 75vw;
      }
    }
  }
}
@media (min-width: 1245px) and (max-height: 660px) {
  .slide1 {
    // .mobile {
    //   top: 9vmax;

    //   left: 10vw;

    //   position: absolute;
    //   width: 30vmax;
    //   z-index: 1;
    //   img {
    //     height: 70vmin;
    //   }
    // }
    .mobile-video {
      top: 20vh;

      left: 12%;
      border: 6px solid rgb(218, 216, 216);
      position: absolute;
      width: 42.6vh;
      z-index: 2;
      object-fit: cover;
      height: 76.5vh;
      border-radius: 5vmin;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
      video {
        border: 1vmin solid black;
        height: 75vh;
        border-radius: 4vmin;
        width: 22vw;
        object-fit: cover;
      }
    }
    .slide1-heading {
      top: 20vh;
      display: flex;
      flex-direction: column;
      margin-left: 10vw;
      margin-right: auto;
      left: 25vw;
      right: 0;
      text-align: center;
      position: absolute;
      width: 35vw;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 2.7vw;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      .title-img {
        width: 25vw;
        height: 18vh;
      }

      .slide1-text {
        margin-top: 5vh;
        font-size: 2.5vh;
      }
      .downloads {
        display: flex;
        justify-content: center;
        gap: 2vmin;
        margin-top: 10vh;
        flex-wrap: wrap;
        img {
          width: 15vw;
          height: 8vh;
        }
        img:hover {
          transform: scale(1.05);
          transition: transform 0.2s;
        }
      }
    }

    .juggler {
      top: 25vh;

      right: 8%;

      position: absolute;
      width: 18%;
      img {
        height: 70vh;
      }
    }
  }
}
@media (max-width: 920px) and (min-height: 1178px) {
  .main-content-games {
    font-family: "Poppins", sans-serif;

    margin-top: -2vh;
    background: linear-gradient(180deg, #0f0b0c 15%, #0e2d5d 80%, #1a4581 100%);
    min-height: 100vh;
    padding-bottom: 10vh;
    .heading-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .game-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 80%;
      text-align: center;
      h1 {
        margin-top: 20vh;
        font-size: 6vh;
        font-weight: 900;
      }
      p {
        color: $secondary;
        font-size: 2vmin;
      }
    }

    // .slick-prev,
    // .slick-next {
    //   display: none !important;
    // }

    // display: flex !important;

    // justify-content: space-evenly !important;
    // flex-wrap: wrap !important;
    // align-items: end !important;
    .games {
      font-family: "Poppins", sans-serif;

      margin: auto;
      margin-top: 12vh;

      width: 70vw;
    }
    .game {
      display: flex !important;
      flex-direction: column;
      color: white;
      text-align: start;
      align-items: start !important;
      font-family: "Poppins", sans-serif;

      h2 {
        margin-top: 2vmin;
        font-weight: 900;
        margin-left: 2vmin;
        font-size: 2.5vmin;
      }
      p {
        margin-top: 1vmin;
        font-weight: 400;
        margin-left: 2vmin;
        color: $secondary;
        font-size: 2vmin;
        text-align: center;
      }

      .playstore {
        height: 4vmin;
        width: 7vmax;
        margin-top: 3vmin;
        margin-left: 2vmin;
      }
      .playstore:hover {
        opacity: 0.7;
      }
    }

    .explore-button {
      font-family: "Poppins", sans-serif;

      display: flex;

      justify-content: center;
      margin-top: 15vh;

      button {
        color: white;
        background-color: #a01b25;
        font-size: 2.4vmin;
        font-weight: 900;
        width: 28vw;
        height: 7vh;
      }
      button:hover {
        opacity: 0.85;
      }
    }
  }
  .faq-game-accordion {
    font-family: "Poppins", sans-serif;
    margin-left: 8vw;
    align-items: flex-start !important;
    margin-top: 5vh !important;
    width: 80vw !important;
    .accordion-game-item {
      text-align: left;
      padding: 2vw !important;
    }

    .accordion-game-question {
      font-family: "Poppins", sans-serif;

      color: white;
      position: relative;
      cursor: pointer;
      width: 70vw;
      text-align: start;
      font-size: 1.8vh;
    }
    .accordion-game-question:after {
      display: inline-block;
      background-image: url(../../images/games/images/plus.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      margin-top: 0.2vh;
      right: -4vw;
      padding: 1vh;
      content: "";
      background-size: 20px 20px;
      height: 0.7vw;
      width: 0.7vw;
      margin-left: 10vw;
      transition: transform 0.2s ease-in-out;
    }
    .accordion-game-question[aria-expanded="true"]:after {
      transform: rotate(120deg) scale(4) rotate(105deg) scale(0.25);
      transition: transform 0.7s;
    }
    .accordion-game-question[aria-expanded="false"]:after {
      transition: transform 0.7s;
    }

    .accordion-game-answer {
      font-family: "Poppins", sans-serif;

      padding-top: 1.8vh;
      width: 70vw;
      color: $secondary;
    }
    .accordion__panel {
      padding: 0;
    }
  }
}

@media (max-width: 913px) {
  .game-faq {
    margin-top: 10vh;
    color: white;
    font-size: 4vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    h1 {
      margin-left: 8vw;
    }
  }
  .faq-game-accordion {
    font-family: "Poppins", sans-serif;
    margin-left: 8vw;
    align-items: flex-start !important;
    margin-top: 5vh !important;
    width: 80vw !important;
    .accordion-game-item {
      text-align: left;
      padding: 2vw !important;
    }

    .accordion-game-question {
      font-family: "Poppins", sans-serif;

      color: white;
      position: relative;
      cursor: pointer;
      width: 70vw;
      text-align: start;
      font-size: 1.7vh;
    }
    .accordion-game-question:after {
      display: inline-block;
      background-image: url(../../images/games/images/plus.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      margin-top: 0.2vh;
      right: -8vw;
      padding: 1vh;
      content: "";
      background-size: 20px 20px;
      height: 0.7vw;
      width: 0.7vw;
      margin-left: 10vw;
      transition: transform 0.2s ease-in-out;
    }
    .accordion-game-question[aria-expanded="true"]:after {
      transform: rotate(120deg) scale(4) rotate(105deg) scale(0.25);
      transition: transform 0.7s;
    }
    .accordion-game-question[aria-expanded="false"]:after {
      transition: transform 0.7s;
    }

    .accordion-game-answer {
      font-family: "Poppins", sans-serif;

      padding-top: 1.5vh;
      width: 70vw;
      color: $secondary;
      font-size: 1.7vh;
    }
    .accordion__panel {
      padding: 0;
    }
  }
  .react-accessible-accordion__item--active {
    background-color: transparent !important;
    /* You can also set other background properties as needed */
  }
  .category-filter {
    font-family: "Poppins", sans-serif;

    margin-top: 10vmin !important;
    width: 95%;
    display: flex;
    justify-content: space-around;
    margin: auto;

    .category-box {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #204a89;
      width: 15vw;
      height: 5vh;
      text-align: center;
      border-radius: 0.6vh;
      cursor: pointer;
      font-size: 1.5vh;
    }
    .category-box:hover {
      opacity: 0.8;
    }
    .active {
      color: white;
      background-color: #2e68ea;
      transform: scale(1.1);
      transition: transform 0.2s;
      font-weight: 900;
    }
    .active:hover {
      opacity: 1;
    }
  }
  .main-content-games {
    font-family: "Poppins", sans-serif;

    margin-top: -10vh;
    background: linear-gradient(180deg, #0f0b0c 15%, #0e2d5d 80%, #1a4581 100%);
    min-height: 100vh;
    padding-bottom: 6vh;
    .heading-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .game-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 90%;
      text-align: center;
      h1 {
        margin-top: 20vh;
        font-size: 4vh;
        font-weight: 900;
      }
      p {
        color: $secondary;
        font-size: 4vmin;
      }
    }

    // .slick-prev,
    // .slick-next {
    //   display: none !important;
    // }

    // display: flex !important;

    // justify-content: space-evenly !important;
    // flex-wrap: wrap !important;
    // align-items: end !important;
    .games {
      font-family: "Poppins", sans-serif;

      margin: auto;
      margin-top: 12vh;

      width: 67vw;
    }
    .game {
      display: flex !important;
      flex-direction: column;
      color: white;
      text-align: start;
      align-items: center !important;
      font-family: "Poppins", sans-serif;

      h2 {
        margin-top: 2vmin;
        font-weight: 900;
        margin-left: 2vmin;
        font-size: 3.1vmin;
        text-align: center !important;
        width: 100% !important;
      }
      p {
        margin-top: 1vmin;
        font-weight: 400;
        margin-left: 2vmin;
        color: $secondary;
        font-size: 2.8vmin;
        text-align: center;
        width: 97% !important;
      }

      .playstore {
        height: 4vmin;
        width: 7vmax;
        margin-top: 3vmin;
        margin-left: 2vmin;
      }
      .playstore:hover {
        opacity: 0.7;
      }
    }

    .explore-button {
      font-family: "Poppins", sans-serif;

      display: flex;

      justify-content: center;
      margin-top: 10vh;

      button {
        color: white;
        background-color: #a01b25;
        font-size: 2.8vmin;
        font-weight: 900;
        width: 24vw;
        height: 5vh;
      }
      button:hover {
        opacity: 0.85;
      }
    }
  }
  .slide2 {
    .slide2-heading {
      top: 12vh;

      margin-right: auto;
      left: 10%;
      right: 0;
      width: 37%;
      position: absolute;

      color: black;

      h1 {
        font-size: 2.5vh;
        font-weight: 1000;
      }
      h2 {
        font-size: 2vh;
        font-weight: 400;
        margin-top: -1vh;
      }
      p {
        margin-top: 1vh;
        font-size: 1.2vh;
      }
      .downloads {
        display: flex;

        gap: 2vmin;
        margin-top: 2vh;
        img {
          width: 10vw;
          height: 2vh;
        }
        img:hover {
          transform: scale(1.05);
          transition: transform 0.2s;
        }
      }
    }
  }
  .slide1 {
    position: relative;
    font-family: "Poppins", sans-serif;

    .bg1 {
      background-image: url(../../images/games/images/Knife_hit_BG.png);
      background-size: cover;
      background-position: center;
      min-width: 100vw;
      min-height: 100vmin;

      left: 0;
      z-index: -1;
      background-repeat: no-repeat;
    }
    .bg1::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25vh; /* Adjust the height of the shadow as needed */
      background: linear-gradient(to top, rgb(12, 12, 12) 0%, transparent 100%);
      pointer-events: none;
    }
  }

  .slide1 {
    // .mobile {
    //   top: 9vmax;

    //   left: 10vw;

    //   position: absolute;
    //   width: 30vmax;
    //   z-index: 1;
    //   img {
    //     height: 70vmin;
    //   }
    // }
    .mobile-video {
      top: 25.3vw;

      left: 8.8vw;
      border: 0.9vw solid rgb(218, 216, 216);
      position: absolute;
      width: 23.6vw;
      z-index: 2;
      object-fit: cover;
      height: 46.5vmin;
      border-radius: 5vmin;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
      video {
        border: 0.7vmin solid black;
        height: 45vmin;
        border-radius: 4vmin;
        width: 22vw;
        object-fit: cover;
      }
    }
    .slide1-heading {
      top: 25.3vw;
      display: flex;
      flex-direction: column;
      margin-left: 7vw;
      margin-right: auto;
      left: 37.6vw;
      right: 0;
      text-align: center;
      position: absolute;
      width: 45vw;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 2.7vw;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      .title-img {
        width: 43vw;
        height: 18vw;
      }

      .slide1-text {
        margin-top: 2vh;
        font-size: 2vh;
      }
      .downloads {
        display: flex;
        justify-content: center;
        gap: 2vmin;
        margin-top: 10vw;
        flex-wrap: wrap;
        img {
          width: 14vw;
          height: 2.7vh;
        }
        img:hover {
          transform: scale(1.05);
          transition: transform 0.2s;
        }
      }
    }

    .juggler {
      top: 9vmax;
      display: none;
      right: 3%;

      position: absolute;
      width: 23%;
      img {
        height: 40vh;
      }
    }
  }
  .cards {
    font-family: "Poppins", sans-serif;

    width: 70vw;
    display: flex;
    justify-content: start;
    margin: auto;
    color: white;
    margin-top: 10vmin;
    flex-wrap: wrap;
    row-gap: 8vmin;
    column-gap: 5vmin;
    img {
      height: 30vmin;
      width: 30vmin;
      border-radius: 1vh;
    }
    h2 {
      text-align: center;
      margin-top: 2vmin;
      font-weight: 700;
      font-size: 3vmin;
    }
    p {
      margin-top: 1vmin;
      text-align: center;
      font-size: 2.5vmin;
      color: $secondary;
    }
    .download-game {
      height: 4vmin;
      width: 7vmax;

      margin: auto;
      margin-top: 3vmin;
    }
    .download-game:hover {
      opacity: 0.7;
    }
  }
}

@media (max-width: 539px) {
  .game-faq {
    margin-top: 10vh;
    color: white;
    font-size: 4vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    h1 {
      margin-left: 8vw;
    }
  }
  .faq-game-accordion {
    font-family: "Poppins", sans-serif;
    margin-left: 8vw;
    align-items: flex-start !important;
    margin-top: 5vh !important;
    width: 90vw !important;
    .accordion-game-item {
      text-align: left;
      padding: 2vw !important;
    }

    .accordion-game-question {
      font-family: "Poppins", sans-serif;

      color: white;
      position: relative;
      cursor: pointer;
      width: 65vw !important;
      text-align: start;
      font-size: 1.5vh;
    }
    .accordion-game-question:after {
      display: inline-block;
      background-image: url(../../images/games/images/plus.svg);
      background-repeat: no-repeat;
      background-position: start;
      position: absolute;
      margin-top: -2vh;
      right: -8vw;
      padding: 1vh;
      content: "";
      background-size: 15px 15px;
      height: 0.7vw;
      width: 0.7vw;
      margin-left: 10vw;
      transition: transform 0.2s ease-in-out;
    }
    .accordion-game-question[aria-expanded="true"]:after {
      transform: rotate(120deg) scale(4) rotate(105deg) scale(0.25);
      transition: transform 0.7s;
    }
    .accordion-game-question[aria-expanded="false"]:after {
      transition: transform 0.7s;
    }

    .accordion-game-answer {
      font-family: "Poppins", sans-serif;

      padding-top: 1.5vh;
      width: 65vw;
      color: $secondary;
    }
    .accordion__panel {
      padding: 0;
    }
  }
  .react-accessible-accordion__item--active {
    background-color: transparent !important;
    /* You can also set other background properties as needed */
  }
  .category-filter {
    font-family: "Poppins", sans-serif;

    margin-top: 10vmin !important;
    width: 95%;
    display: flex;
    justify-content: space-around;
    margin: auto;

    .category-box {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #204a89;
      width: 15vw;
      height: 5vh;
      text-align: center;
      border-radius: 0.6vh;
      cursor: pointer;
      font-size: 1.5vh;
    }
    .category-box:hover {
      opacity: 0.8;
    }
    .active {
      color: white;
      background-color: #2e68ea;
      transform: scale(1.1);
      transition: transform 0.2s;
      font-weight: 900;
    }
    .active:hover {
      opacity: 1;
    }
  }
  .main-content-games {
    font-family: "Poppins", sans-serif;

    margin-top: -10vh;
    background: linear-gradient(180deg, #0f0b0c 15%, #0e2d5d 80%, #1a4581 100%);
    min-height: 100vh;
    padding-bottom: 6vh;
    .heading-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .game-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      width: 90%;
      text-align: center;
      h1 {
        margin-top: 20vh;
        font-size: 4vh;
        font-weight: 900;
      }
      p {
        color: $secondary;
        font-size: 4vmin;
      }
    }

    // .slick-prev,
    // .slick-next {
    //   display: none !important;
    // }

    // display: flex !important;

    // justify-content: space-evenly !important;
    // flex-wrap: wrap !important;
    // align-items: end !important;
    .games {
      font-family: "Poppins", sans-serif;

      margin: auto;
      margin-top: 12vh;

      width: 67vw;
    }
    .game {
      display: flex !important;
      flex-direction: column;
      color: white;
      text-align: start;
      align-items: center !important;
      font-family: "Poppins", sans-serif;

      h2 {
        margin-top: 2vmin;
        font-weight: 900;
        margin-left: 2vmin;
        font-size: 3.5vmin;
      }
      p {
        margin-top: 1vmin;
        font-weight: 400;
        margin-left: 2vmin;
        color: $secondary;
        font-size: 3vmin;
        text-align: center;
      }

      .playstore {
        height: 4vmin;
        width: 7vmax;
        margin-top: 3vmin;
        margin-left: 2vmin;
      }
      .playstore:hover {
        opacity: 0.7;
      }
    }

    .explore-button {
      font-family: "Poppins", sans-serif;

      display: flex;

      justify-content: center;
      margin-top: 10vh;

      button {
        color: white;
        background-color: #a01b25;
        font-size: 2.8vmin;
        font-weight: 900;
        width: 24vw;
        height: 5vh;
      }
      button:hover {
        opacity: 0.85;
      }
    }
  }
  .slide2 {
    .slide2-heading {
      top: 12vh;

      margin-right: auto;
      left: 10%;
      right: 0;
      width: 37%;
      position: absolute;

      color: black;

      h1 {
        font-size: 2.5vh;
        font-weight: 1000;
      }
      h2 {
        font-size: 2vh;
        font-weight: 400;
        margin-top: -1vh;
      }
      p {
        margin-top: 1vh;
        font-size: 1vh;
      }
      .downloads {
        display: flex;

        gap: 2vmin;
        margin-top: 2vh;
        img {
          width: 10vw;
          height: 2vh;
        }
        img:hover {
          transform: scale(1.05);
          transition: transform 0.2s;
        }
      }
    }
  }
  .slide1 {
    position: relative;
    font-family: "Poppins", sans-serif;

    .bg1 {
      background-image: url(../../images/games/images/Knife_hit_BG.png);
      background-size: cover;
      background-position: center;
      min-width: 100vw;
      min-height: 100vmin;

      left: 0;
      z-index: -1;
      background-repeat: no-repeat;
    }
    .bg1::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25vh; /* Adjust the height of the shadow as needed */
      background: linear-gradient(to top, rgb(12, 12, 12) 0%, transparent 100%);
      pointer-events: none;
    }
  }

  .slide1 {
    // .mobile {
    //   top: 9vmax;

    //   left: 10vw;

    //   position: absolute;
    //   width: 30vmax;
    //   z-index: 1;
    //   img {
    //     height: 70vmin;
    //   }
    // }
    .mobile-video {
      top: 25.3vw;

      left: 8.8vw;
      border: 0.9vw solid rgb(218, 216, 216);
      position: absolute;
      width: 23.6vw;
      z-index: 2;
      object-fit: cover;
      height: 46.5vmin;
      border-radius: 5vmin;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.8);
      video {
        border: 0.7vmin solid black;
        height: 45vmin;
        border-radius: 4vmin;
        width: 22vw;
        object-fit: cover;
      }
    }
    .slide1-heading {
      top: 25.3vw;
      display: flex;
      flex-direction: column;
      margin-left: 7vw;
      margin-right: auto;
      left: 37.6vw;
      right: 0;
      text-align: center;
      position: absolute;
      width: 45vw;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 2.7vw;
      font-weight: 300;
      font-family: "Poppins", sans-serif;
      .title-img {
        width: 43vw;
        height: 18vw;
      }

      .slide1-text {
        margin-top: 2vh;
        font-size: 1vh;
      }
      .downloads {
        display: flex;
        justify-content: center;
        gap: 2vmin;
        margin-top: 10vw;
        flex-wrap: wrap;
        img {
          width: 14vw;
          height: 2.7vh;
        }
        img:hover {
          transform: scale(1.05);
          transition: transform 0.2s;
        }
      }
    }

    .juggler {
      top: 9vmax;
      display: none;
      right: 3%;

      position: absolute;
      width: 23%;
      img {
        height: 40vh;
      }
    }
  }
  .cards {
    font-family: "Poppins", sans-serif;

    width: 70vw;
    display: flex;
    justify-content: start;
    margin: auto;
    color: white;
    margin-top: 10vmin;
    flex-wrap: wrap;
    row-gap: 8vmin;
    column-gap: 5vmin;
    img {
      height: 30vmin;
      width: 30vmin;
      border-radius: 1vh;
    }
    h2 {
      text-align: center;
      margin-top: 2vmin;
      font-weight: 700;
      font-size: 3vmin;
    }
    p {
      margin-top: 1vmin;
      text-align: center;
      font-size: 2.5vmin;
      color: $secondary;
    }
    .download-game {
      height: 4vmin;
      width: 7vmax;

      margin: auto;
      margin-top: 3vmin;
    }
    .download-game:hover {
      opacity: 0.7;
    }
  }
}
