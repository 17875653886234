@tailwind base;
@tailwind components;
@tailwind utilities;

body:-webkit-scrollbar {
  display: none;
}

a {
  color: blue;
}

.apps-install-now {
  color: rgba(26, 65, 207, 0.849);
  background-color: white;
  border-radius: 1.3vmin;
  font-size: 2.1vmin;
  font-weight: 600;
  padding: 1vmin 2vmin;
}
