.background-container {
  position: relative;
  width: 100vw;
  height: 60vh;
  background-color: #000000;
  text-align: center;

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 55vh;
    background-image: url('./assets/final_bg.jpg');
    background-size: cover;
    background-position: bottom;
    }
}

@media (min-width: 768px) {
  .background-container {
    position: relative;
    width: 100vw;
    height: 80vh;
   
    text-align: left;
    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 80vh;
      background-image: url('./assets/final_bg.jpg');
      background-size: cover;
      background-position: bottom;  
    }
  }
}
// @media (min-width: 768px) {
  .yellow {
    background: linear-gradient(180deg, rgb(50, 42, 7) , black) padding-box,
    linear-gradient(180deg,#9c7d0a 30%, rgb(27, 27, 27) 70%) border-box;
  border-radius: 25px;
  border: 2px solid transparent;
  }
  
  .pink{
    background: linear-gradient(180deg, #3c071a , black) padding-box,
    linear-gradient(180deg,#93516a 20%, rgb(27, 27, 27) 80%) border-box;
  border-radius: 25px;
  border: 2px solid transparent;
  }
  
  .red{
    background: linear-gradient(180deg, #450606 30%, black 70%) padding-box,
    linear-gradient(180deg,#DF625B 20%, rgb(27, 27, 27) 80%) border-box;
  border-radius: 25px;
  border: 2px solid transparent;
  }
  
  .green{
    background: linear-gradient(180deg, #1b3a0d 30%, black 70%) padding-box,
    linear-gradient(180deg,#8EE965 20%, rgb(27, 27, 27) 80%) border-box;
  border-radius: 25px;
  border: 2px solid transparent; 
  }
  
  .blue{
    background: linear-gradient(180deg, #0a2b3a 30%, black 70%) padding-box,
    linear-gradient(180deg,#45A6D2 20%, rgb(27, 27, 27) 80%) border-box;
  border-radius: 25px;
  border: 2px solid transparent; 
  }
  .purple{
    background: linear-gradient(180deg, #200633 30%, black 70%) padding-box,
    linear-gradient(180deg,#D08DFF 20%, rgb(27, 27, 27) 80%) border-box;
  border-radius: 25px;
  border: 2px solid transparent; 
  }
  


// }

.slick-current .slider-icon {
  background: linear-gradient(30deg, 
    #35C6FA -16.14%, 
    #2693F2 28.95%, 
    #1D76EC 43.65%, 
    #104BE5 69.13%, 
    #0B3BE2 81.87%);
}
.slider-icon{
  background-color: #D9D9D933;
}


.slider-icon img {
  width: 40px;
  height: 40px;
}

 